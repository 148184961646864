import { Pipe, PipeTransform } from '@angular/core';

export type KeyOrTranslationObject = string | RmaTranslationObject;

export interface RmaTranslationObject {
  key: string;
  params?: object;
}

@Pipe({
  name: 'translationObject',
  standalone: true,
})
export class TranslationObjectPipe implements PipeTransform {
  transform(value: string | RmaTranslationObject | null | undefined): RmaTranslationObject | null {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      return { key: value };
    }

    return value;
  }
}

@Pipe({
  name: 'isTranslationObject',
  standalone: true,
})
export class IsTranslationObjectPipe implements PipeTransform {
  transform(value: string | RmaTranslationObject | null | undefined): value is RmaTranslationObject {
    return (value as RmaTranslationObject).key !== undefined;
  }
}
